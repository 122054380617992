import React, { useEffect } from 'react'
import Wrapper from '../../UI/Wrapper'
import Sidebar from '../../components/Common/Sidebar'
import Main from '../../components/FormScreen/Main'
import Left from '../../components/Coach/Welcome/Left'
import Calendar from '../../components/Coach/Welcome/Calendar'
import ReactGA from "react-ga"

const WelcomeCoach = () => {

  //Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
    <Wrapper>
      <div className="flex">
        <Sidebar notLoggedIn={false} />
        <Main>
          <div className='flex mx-auto space-x-20'>
            <Left />
            <Calendar />
          </div>

        </Main>
      </div>
    </Wrapper>
  )
}

export default WelcomeCoach