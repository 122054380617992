import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from '../../UI/landingPage/Container'
import verticalsDesign from '../../assets/images/lp-footer-verticals.png'
import { Link } from 'react-router-dom'
import facebookIcon from '../../assets/images/lp-facebook.png'
import linkedinIcon from '../../assets/images/lp-linkedin.png'
import twitterIcon from '../../assets/images/lp-twitter.png'
import instagramIcon from '../../assets/images/lp-instagram.png'

import logo from '../../assets/images/lp-logo.svg'

const footerLinks = [
  {
    id: 1,
    text: "Home",
    link: "home",
    target: ""
  },
  {
    id: 2,
    text: "About Us",
    link: "about",
    target: ""
  },
  {
    id: 3,
    text: "Testimonials",
    link: "testimonials",
    target: ""
  },
  {
    id: 4,
    text: "Contact Us",
    link: "contact",
    target: ""
  },
  {
    id: 5,
    text: "Login",
    link: "https://app.hapsmiths.com/login",
    target: "default"
  },
  {
    id: 6,
    text: "Schedule Demo",
    link: "https://calendly.com/haspmiths/demo-happiness",
    target: "blank"
  }
]

const socialLinks = [
  // {
  //   id: 1,
  //   icon: twitterIcon,
  //   link: "#"
  // },
  {
    id: 1,
    icon: instagramIcon,
    link: "https://www.instagram.com/hapsmiths"
  },
  {
    id: 2,
    icon: linkedinIcon,
    link: "https://www.linkedin.com/company/hapsmiths/"
  }
]

const year = new Date().getFullYear()

const Footer = ({ handleClickScroll }) => {

  useEffect(() => {
    AOS.init({ duration: 1000, once: true, })
  }, [])

  return (
    <div className='bg-[#0C1717]'>
      <Container>
        <div className='flex gap-8'>
          <div className='w-[25%] lp-lg:hidden lp-md:hidden lp-sm:hidden'>
            <img src={verticalsDesign} alt="hapsmiths verticals" data-aos='fade-right' />
          </div>

          <div className='w-[75%] lp-lg:w-[100%] lp-md:w-full lp-sm:w-full flex justify-between lp-lg:justify-center items-center'>
            <div className='flex lp-lg:hidden lp-md:hidden lp-sm:hidden flex-col gap-10 w-[75%]'>
              <ul className='flex gap-8' data-aos='fade-right'>
                {footerLinks?.map(link => (
                  <li key={link.id} className="text-[15px] text-[#FDE9D8] relative block uLine-animation">
                    <span className="cursor-pointer" onClick={() => handleClickScroll(link.link, link.target)}>{link.text}</span>
                  </li>
                ))}
              </ul>
              {/* <div>
                <h4 className='text-[#FDF5EE] font-semibold'>Info:</h4>
                <p className='text-[#FDF5EE] text-[15px] font-light'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massasit amet sapien fringilla, mattis ligula consectetur.</p>
              </div> */}
              <div data-aos='fade-right'>
                <ul className='flex gap-3 text-[#FDF5EE] font-light text-[14px]'>
                  <Link to='/terms-conditions-privacy?1' target="black">Terms and Conditions</Link>
                  <Link to='/terms-conditions-privacy?2' target="black">Privacy Policy</Link>
                </ul>
                <div className='text-[#FDF5EE] font-light text-[14px]'>
                  All rights reserved © Hapsmiths {year}
                </div>
              </div>
            </div>
            <div className='flex lp-lg:hidden lp-md:hidden lp-sm:hidden flex-col gap-8 items-end w-[25%]' data-aos='fade-right'>
              <ul className='flex flex-col gap-2 items-center'>
                {socialLinks.map((item) => (
                  <a key={item.id} href={item.link} target='blank'>
                    <img src={item.icon} alt="social icons" />
                  </a>
                ))}
              </ul>

              <div className='flex justify-center items-center'>
                <img src={logo} alt="Logo" className='inline-block w-[18px] mr-1' />
                <span className='font-constantia text-[#fff] text-[30px]'>Hapsmiths</span>
              </div>
            </div>

            <div className='hidden lp-lg:flex lp-md:flex lp-sm:flex flex-col gap-4 lp-md:gap-1 lp-sm:gap-1 items-center lp-md:pt-5 lp-md:pb-2 lp-sm:pt-5 lp-sm:pb-2 w-full' data-aos='fade-right'>
              <ul className='flex gap-5 lp-md:gap-3 lp-md:flex-wrap lp-md:justify-center lp-sm:gap-2 lp-sm:flex-wrap lp-sm:justify-center mb-2'>
                {footerLinks?.map(link => (
                  <li key={link.id} className="text-[15px] text-[#FDE9D8] relative block uLine-animation lp-lg:fifth-child:hidden lp-md:fifth-child:hidden lp-sm:fifth-child:hidden lp-lg:sixth-child:hidden lp-md:sixth-child:hidden lp-sm:sixth-child:hidden">
                    <span className="cursor-pointer" onClick={() => handleClickScroll(link.link, link.target)}>{link.text}</span>
                  </li>
                ))}
              </ul>

              <ul className='flex flex-row gap-2 items-center'>
                {socialLinks.map((item) => (
                  <a key={item.id} href={item.link} target='blank'>
                    <img src={item.icon} alt="social icons" />
                  </a>
                ))}
              </ul>

              <div className='flex justify-center items-center mt-5'>
                <img src={logo} alt="Logo" className='inline-block w-[14px] mr-1' />
                <span className='font-constantia text-[#fff] text-[30px] lp-md:mt-1.5 lp-sm:mt-1.5'>Hapsmiths</span>
              </div>

              <div>
                <ul className='flex gap-3 text-[#FDF5EE] font-light text-[14px]'>
                  <Link to='/terms-conditions-privacy?1' target="black">Terms and Conditions</Link>
                  <Link to='/terms-conditions-privacy?2' target="black">Privacy Policy</Link>
                </ul>
                <div className='text-[#FDF5EE] font-light text-[14px]'>
                  All rights reserved © Hapsmiths {year}
                </div>
              </div>
            </div>
          </div>
        </div >
      </Container >
    </div >
  )
}

export default Footer