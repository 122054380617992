import React, { useState, useEffect } from 'react'
import About from '../components/LandingPage/About'
import ContactUs from '../components/LandingPage/ContactUs'
import Gallery from '../components/LandingPage/Gallery'
import Happiness from '../components/LandingPage/Happiness'
import Header from '../components/LandingPage/Header'
import Testimonials from '../components/LandingPage/Testimonials'
import Footer from '../components/LandingPage/Footer'
import uc from '../assets/images/under-construction.png'
import NavbarDummy from '../components/LandingPage/NavbarDummy'
import ReactGA from "react-ga"

const LandingPage = () => {

  //Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  const [menu, setMenu] = useState(false)

  const handleMenu = () => {
    setMenu((prevState) => !prevState)
  }

  const handleClickScroll = (linkId, target = null) => {
    const element = document.getElementById(linkId);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    if (target === "blank") {
      window.open(linkId, 'blank');
    } else if (target === "default") {
      window.location.href = linkId
    }
  };

  return (
    <div className="w-full bg-[white] relative">
      {/* <div className='lg:hidden xs:hidden md:hidden sm:hidden lp-lg:block'> */}
      <div className='lp-lg:block'>
        {/* <NavbarDummy handleClickScroll={handleClickScroll}/> */}
        <Header menu={menu} handleMenu={handleMenu} handleClickScroll={handleClickScroll} />
        <Happiness />
        <About />
        <Testimonials />
        <Gallery />
        <ContactUs />
        <Footer handleClickScroll={handleClickScroll} />
      </div>

      {/* <div className='bg-[#000] w-[100%] h-[100vh] flex-col justify-center items-center px-3 text-center lg:flex xs:flex md:flex sm:flex lp-lg:hidden'>
        <img src={uc} alt="uc" />
        <p className='text-[#fff] '>Page under construction for smartphone & tablet view. Please view on desktop/laptop.</p>
      </div> */}
    </div>
  )
}

export default LandingPage