import React, { useEffect } from 'react'
import Wrapper from '../../UI/Wrapper'
import Sidebar from '../../components/Common/Sidebar'
import Main from '../../components/FormScreen/Main'
import Left from '../../components/Client/FindTherapist/Left'
import Right from '../../components/Client/FindTherapist/Right'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import ReactGA from "react-ga"

const FindTherapist = () => {
    const [gender, setGender] = useState('')
    const [lgbtq, setLgbtq] = useState('')
    const [language, setLanguage] = useState('')

    const categoryName = useSelector(
        (state) => state.therapistReducer.categoryName
    )

    //Google Analytics
    useEffect( () => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Wrapper>
            <div className="flex">
                <Sidebar findTherapist={true} notLoggedIn={false} />
                <Main>
                    <div className='flex flex-col'>
                        <span className="font-constantia w-full mt-10 ml-12 font-bold text-3xl">
                            { categoryName?.toLowerCase() == ("MENTAL HEALTH").toLowerCase() ? "Find your Therapist" : "Find your Coach"}
                            {/* Find Your Coach - {categoryName} */}
                        </span>
                        <div className="flex gap-0 align-middle findTherapistWrapper">
                            <Left
                                gender={gender}
                                lgbtq={lgbtq}
                                language={language}
                            />
                            <Right
                                setGender={setGender}
                                gender={gender}
                                lgbtq={lgbtq}
                                setLgbtq={setLgbtq}
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </div>
                    </div>
                </Main>
            </div>
        </Wrapper>
    )
}

export default FindTherapist
