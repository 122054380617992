import moment from 'moment'

//Production Server
export const BASE_URL = 'https://admin.hapsmiths.com'
export const CHAT_URL = 'https://chat.hapsmiths.com'

// Six30Labs Staging Server
// export const BASE_URL = 'https://livupbe.six30labs.com'
// export const CHAT_URL = 'https://livupchat.six30labs.com'

export const currentTime = moment(new Date(), 'h:mm:ss A').format('HH:mm')
export const currentDate = moment(new Date()).format('YYYY-MM-DD')

export const OTP_TIMER = 60
