import React, { useEffect } from 'react'
import Wrapper from '../UI/Wrapper'
import Main from '../components/Login/Main'
import Sidebar from '../components/Common/Sidebar'
import Left from '../components/OtpV/Left'
import Right from '../components/OtpV/Right'
import ReactGA from "react-ga"

const OTPverified = () => {

    //Google Analytics
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Wrapper>
            <div className="flex">
                <Sidebar notLoggedIn={true} />
                <Main>
                    <Left />
                    <Right />
                </Main>
                <div className="fixed text-xs bottom-[40px] font-ubuntu xs:w-[200px] right-[40px]">
                    Please note that your data is protected and won’t be used anywhere else.
                </div>
            </div>
        </Wrapper>
    )
}

export default OTPverified